enifed('@glimmer/encoder', ['exports', 'ember-babel'], function (exports, _emberBabel) {
    'use strict';

    exports.InstructionEncoder = undefined;


    var InstructionEncoder = function () {
        function InstructionEncoder(buffer) {
            (0, _emberBabel.classCallCheck)(this, InstructionEncoder);

            this.buffer = buffer;
            this.typePos = 0;
            this.size = 0;
        }

        InstructionEncoder.prototype.encode = function encode(type, machine) {
            if (type > 255 /* TYPE_SIZE */) {
                    throw new Error('Opcode type over 8-bits. Got ' + type + '.');
                }
            this.buffer.push(type | machine | arguments.length - 2 << 8 /* ARG_SHIFT */);
            this.typePos = this.buffer.length - 1;
            for (var i = 2; i < arguments.length; i++) {
                var op = arguments[i];
                if (typeof op === 'number' && op > 4294967295 /* MAX_SIZE */) {
                        throw new Error('Operand over 32-bits. Got ' + op + '.');
                    }
                this.buffer.push(op);
            }
            this.size = this.buffer.length;
        };

        InstructionEncoder.prototype.patch = function patch(position, target) {
            if (this.buffer[position + 1] === -1) {
                this.buffer[position + 1] = target;
            } else {
                throw new Error('Trying to patch operand in populated slot instead of a reserved slot.');
            }
        };

        InstructionEncoder.prototype.patchWith = function patchWith(position, target, operand) {
            if (this.buffer[position + 1] === -1) {
                this.buffer[position + 1] = target;
                this.buffer[position + 2] = operand;
            } else {
                throw new Error('Trying to patch operand in populated slot instead of a reserved slot.');
            }
        };

        return InstructionEncoder;
    }();

    exports.InstructionEncoder = InstructionEncoder;
});