define("ember-composable-helpers/helpers/from-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fromEntries(_ref) {
    let [entries] = _ref;

    if (!entries) {
      return entries;
    }

    return Object.fromEntries(entries);
  });

  _exports.default = _default;
});