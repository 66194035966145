define("ember-composable-helpers/helpers/append", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;

  function append(_ref) {
    let [...arrays] = _ref;
    return [].concat(...arrays);
  }

  var _default = Ember.Helper.helper(append);

  _exports.default = _default;
});