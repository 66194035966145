define("ember-string-fns/helpers/string-slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringSlice = stringSlice;

  /**
   * Slice a string and return the new string.
   *
   * @public
   * @function stringSlice
   * @param {string} value The string to slice.
   * @param {number} beginIndex The beginning index to slice.
   * @param {number} endIndex The optional end index to slice.
   * @returns {string} The sliced string.
   */
  function stringSlice(_ref) {
    let [value = '', beginIndex = 0, endIndex] = _ref;
    let result;

    if (endIndex === undefined) {
      result = value.slice(beginIndex);
    } else {
      result = value.slice(beginIndex, endIndex);
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringSlice);

  _exports.default = _default;
});