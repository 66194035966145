define("ember-string-fns/helpers/string-html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function stringHtmlSafe(params
  /*, hash*/
  ) {
    return params;
  });

  _exports.default = _default;
});