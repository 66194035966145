define("ember-string-fns/helpers/string-starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringStartsWith = stringStartsWith;

  /**
   * Determine if string starts with another string.
   *
   * @public
   * @function stringStartsWith
   * @param {string} haystack The string to search.
   * @param {string} needle The string to look for.
   * @param {boolean} ignoreCase Whether a case insensitive search should be performed.
   * @returns {boolean} True if match is found.
   */
  function stringStartsWith(_ref) {
    let [haystack, needle, ignoreCase = false] = _ref;
    let result = false; // haystack and needle are required

    if (haystack && needle) {
      // simple indexof check
      if (ignoreCase) {
        result = haystack.toLowerCase().indexOf(needle.toLowerCase(), 0) === 0;
      } else {
        result = haystack.indexOf(needle, 0) === 0;
      }
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringStartsWith);

  _exports.default = _default;
});