define("ember-composable-helpers/helpers/inc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.inc = inc;

  function inc(_ref) {
    let [step, val] = _ref;

    if (Ember.isEmpty(val)) {
      val = step;
      step = undefined;
    }

    val = Number(val);

    if (isNaN(val)) {
      return;
    }

    if (step === undefined) {
      step = 1;
    }

    return val + step;
  }

  var _default = Ember.Helper.helper(inc);

  _exports.default = _default;
});