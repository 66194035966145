define("ember-string-fns/helpers/string-ends-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringEndsWith = stringEndsWith;

  /**
   * Determine if string ends with another string.
   *
   * @public
   * @function stringEndsWith
   * @param {string} haystack The string to search.
   * @param {string} needle The string to look for.
   * @param {boolean} ignoreCase Whether a case insensitive search should be performed.
   * @returns {boolean} True if match is found.
   */
  function stringEndsWith(_ref) {
    let [haystack, needle, ignoreCase = false] = _ref;
    let result = false; // haystack and needle are required

    if (haystack && needle) {
      // back position up the number of length of the needle
      let position = haystack.length;
      position -= needle.length; // look for needle by position

      let lastIndex;

      if (ignoreCase) {
        lastIndex = haystack.toLowerCase().indexOf(needle.toLowerCase(), position);
      } else {
        lastIndex = haystack.indexOf(needle, position);
      } // construct result


      result = lastIndex !== -1 && lastIndex === position;
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringEndsWith);

  _exports.default = _default;
});