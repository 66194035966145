define("ember-string-fns/helpers/string-replace-all", ["exports", "ember-string-fns/helpers/string-replace"], function (_exports, _stringReplace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringReplaceAll = stringReplaceAll;

  /**
   * Replace a string with all matches found from another string.
   *
   * @public
   * @function stringReplaceAll
   * @param {string} value The starting string.
   * @param {string} find The string to find.
   * @param {string} replace The string to replace with when found.
   * @param {boolean} ignoreCase Whether a case insensitive search should be performed.
   * @returns {string} The replaced string.
   */
  function stringReplaceAll(_ref) {
    let [value, find, replace = '', ignoreCase = false] = _ref;
    return (0, _stringReplace.stringReplace)([value, find, replace, ignoreCase, true]);
  }

  var _default = Ember.Helper.helper(stringReplaceAll);

  _exports.default = _default;
});