define("ember-composable-helpers/helpers/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filter = filter;

  function filter(_ref) {
    let [callback, array] = _ref;

    if (Ember.isEmpty(callback) || !array) {
      return [];
    }

    return array.filter(callback);
  }

  var _default = Ember.Helper.helper(filter);

  _exports.default = _default;
});