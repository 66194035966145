define("ember-string-fns/helpers/string-trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringTrim = stringTrim;

  /**
   * Trim the start and end of a string.
   *
   * @public
   * @function stringTrim
   * @param {string} value The string to trim.
   * @returns {string} The trimmed string.
   */
  function stringTrim(_ref) {
    let [value = ''] = _ref;
    return value.trim();
  }

  var _default = Ember.Helper.helper(stringTrim);

  _exports.default = _default;
});