define("ember-composable-helpers/helpers/slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;

  function slice(_ref) {
    let [...args] = _ref;
    let array = args.pop();

    if (!array) {
      array = [];
    }

    return array.slice(...args);
  }

  var _default = Ember.Helper.helper(slice);

  _exports.default = _default;
});