define("ember-string-fns/helpers/string-from-char-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringFromCharCode = stringFromCharCode;

  /**
   * Get a character from utf-16 codes.
   *
   * @public
   * @function stringFromCharCode
   * @param {...number} codes The array of codes.
   * @returns {string} The string sequence.
   */
  function stringFromCharCode(_ref) {
    let [...codes] = _ref;
    return String.fromCharCode(...codes);
  }

  var _default = Ember.Helper.helper(stringFromCharCode);

  _exports.default = _default;
});