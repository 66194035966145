define("ember-composable-helpers/helpers/contains", ["exports", "ember-composable-helpers/utils/includes"], function (_exports, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  function _contains(needle, haystack) {
    return (0, _includes.default)(Ember.A(haystack), needle);
  }

  function contains(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    if (Ember.isArray(needle)) {
      return needle.reduce((acc, val) => acc && _contains(val, haystack), true);
    }

    return _contains(needle, haystack);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    let [needle, haystack] = _ref;
    return contains(needle, haystack);
  });

  _exports.default = _default;
});