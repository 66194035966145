define("ember-string-fns/helpers/string-to-kebab-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToKebabCase = stringToKebabCase;

  /**
   * kebab-case a string.
   *
   * @public
   * @function stringToKebabCase
   * @param {string} value The string to kebab-case.
   * @returns {string} The kebab-cased string.
   */
  function stringToKebabCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().split(' ').join('-');
  }

  var _default = Ember.Helper.helper(stringToKebabCase);

  _exports.default = _default;
});