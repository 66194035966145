define("ember-string-fns/helpers/string-to-sentence-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToSentenceCase = stringToSentenceCase;

  /**
   *  Sentence case. a string.
   *
   * @public
   * @function stringToSentenceCase
   * @param {string} value The string to Sentence case.
   * @returns {string} The Sentence cased. string.
   */
  function stringToSentenceCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, match => match.toUpperCase());
  }

  var _default = Ember.Helper.helper(stringToSentenceCase);

  _exports.default = _default;
});