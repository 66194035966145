define("ember-composable-helpers/helpers/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function values(_ref) {
    let [object] = _ref;

    if (!object) {
      return object;
    }

    return Object.values(object);
  });

  _exports.default = _default;
});