enifed('@glimmer/node', ['exports', 'ember-babel', '@glimmer/runtime'], function (exports, _emberBabel, _runtime) {
    'use strict';

    exports.serializeBuilder = exports.NodeDOMTreeConstruction = undefined;


    var NodeDOMTreeConstruction = function (_DOMTreeConstruction) {
        (0, _emberBabel.inherits)(NodeDOMTreeConstruction, _DOMTreeConstruction);

        function NodeDOMTreeConstruction(doc) {
            (0, _emberBabel.classCallCheck)(this, NodeDOMTreeConstruction);

            return (0, _emberBabel.possibleConstructorReturn)(this, _DOMTreeConstruction.call(this, doc));
        }
        // override to prevent usage of `this.document` until after the constructor


        NodeDOMTreeConstruction.prototype.setupUselessElement = function setupUselessElement() {};

        NodeDOMTreeConstruction.prototype.insertHTMLBefore = function insertHTMLBefore(parent, reference, html) {
            var prev = reference ? reference.previousSibling : parent.lastChild;
            var raw = this.document.createRawHTMLSection(html);
            parent.insertBefore(raw, reference);
            var first = prev ? prev.nextSibling : parent.firstChild;
            var last = reference ? reference.previousSibling : parent.lastChild;
            return new _runtime.ConcreteBounds(parent, first, last);
        };
        // override to avoid SVG detection/work when in node (this is not needed in SSR)


        NodeDOMTreeConstruction.prototype.createElement = function createElement(tag) {
            return this.document.createElement(tag);
        };
        // override to avoid namespace shenanigans when in node (this is not needed in SSR)


        NodeDOMTreeConstruction.prototype.setAttribute = function setAttribute(element, name, value) {
            element.setAttribute(name, value);
        };

        return NodeDOMTreeConstruction;
    }(_runtime.DOMTreeConstruction);

    var TEXT_NODE = 3;
    function currentNode(cursor) {
        var element = cursor.element,
            nextSibling = cursor.nextSibling;

        if (nextSibling === null) {
            return element.lastChild;
        } else {
            return nextSibling.previousSibling;
        }
    }

    var SerializeBuilder = function (_NewElementBuilder) {
        (0, _emberBabel.inherits)(SerializeBuilder, _NewElementBuilder);

        function SerializeBuilder() {
            (0, _emberBabel.classCallCheck)(this, SerializeBuilder);

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _NewElementBuilder.apply(this, arguments));

            _this2.serializeBlockDepth = 0;
            return _this2;
        }

        SerializeBuilder.prototype.__openBlock = function __openBlock() {
            var depth = this.serializeBlockDepth++;
            this.__appendComment('%+b:' + depth + '%');
            _NewElementBuilder.prototype.__openBlock.call(this);
        };

        SerializeBuilder.prototype.__closeBlock = function __closeBlock() {
            _NewElementBuilder.prototype.__closeBlock.call(this);
            this.__appendComment('%-b:' + --this.serializeBlockDepth + '%');
        };

        SerializeBuilder.prototype.__appendHTML = function __appendHTML(html) {
            // Do we need to run the html tokenizer here?
            var first = this.__appendComment('%glmr%');
            if (this.element.tagName === 'TABLE') {
                var openIndex = html.indexOf('<');
                if (openIndex > -1) {
                    var tr = html.slice(openIndex + 1, openIndex + 3);
                    if (tr === 'tr') {
                        html = '<tbody>' + html + '</tbody>';
                    }
                }
            }
            if (html === '') {
                this.__appendComment('% %');
            } else {
                _NewElementBuilder.prototype.__appendHTML.call(this, html);
            }
            var last = this.__appendComment('%glmr%');
            return new _runtime.ConcreteBounds(this.element, first, last);
        };

        SerializeBuilder.prototype.__appendText = function __appendText(string) {
            var current = currentNode(this);
            if (string === '') {
                return this.__appendComment('% %');
            } else if (current && current.nodeType === TEXT_NODE) {
                this.__appendComment('%|%');
            }
            return _NewElementBuilder.prototype.__appendText.call(this, string);
        };

        SerializeBuilder.prototype.closeElement = function closeElement() {
            if (this.element['needsExtraClose'] === true) {
                this.element['needsExtraClose'] = false;
                _NewElementBuilder.prototype.closeElement.call(this);
            }
            _NewElementBuilder.prototype.closeElement.call(this);
        };

        SerializeBuilder.prototype.openElement = function openElement(tag) {
            if (tag === 'tr') {
                if (this.element.tagName !== 'TBODY') {
                    this.openElement('tbody');
                    // This prevents the closeBlock comment from being re-parented
                    // under the auto inserted tbody. Rehydration builder needs to
                    // account for the insertion since it is injected here and not
                    // really in the template.
                    this.constructing['needsExtraClose'] = true;
                    this.flushElement();
                }
            }
            return _NewElementBuilder.prototype.openElement.call(this, tag);
        };

        SerializeBuilder.prototype.pushRemoteElement = function pushRemoteElement(element, cursorId) {
            var nextSibling = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            var dom = this.dom;

            var script = dom.createElement('script');
            script.setAttribute('glmr', cursorId);
            dom.insertBefore(element, script, nextSibling);
            _NewElementBuilder.prototype.pushRemoteElement.call(this, element, cursorId, nextSibling);
        };

        return SerializeBuilder;
    }(_runtime.NewElementBuilder);

    function serializeBuilder(env, cursor) {
        return SerializeBuilder.forInitialRender(env, cursor);
    }

    exports.NodeDOMTreeConstruction = NodeDOMTreeConstruction;
    exports.serializeBuilder = serializeBuilder;
});