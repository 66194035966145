enifed("@ember/-internals/routing/lib/system/cache", ["exports", "ember-babel"], function (exports, _emberBabel) {
    "use strict";

    /**
      A two-tiered cache with support for fallback values when doing lookups.
      Uses "buckets" and then "keys" to cache values.
    
      @private
      @class BucketCache
    */
    var BucketCache = function () {
        function BucketCache() {
            (0, _emberBabel.classCallCheck)(this, BucketCache);

            this.cache = new Map();
        }

        BucketCache.prototype.has = function has(bucketKey) {
            return this.cache.has(bucketKey);
        };

        BucketCache.prototype.stash = function stash(bucketKey, key, value) {
            var bucket = this.cache.get(bucketKey);
            if (bucket === undefined) {
                bucket = new Map();
                this.cache.set(bucketKey, bucket);
            }
            bucket.set(key, value);
        };

        BucketCache.prototype.lookup = function lookup(bucketKey, prop, defaultValue) {
            if (!this.has(bucketKey)) {
                return defaultValue;
            }
            var bucket = this.cache.get(bucketKey);
            if (bucket.has(prop)) {
                return bucket.get(prop);
            } else {
                return defaultValue;
            }
        };

        return BucketCache;
    }();

    exports.default = BucketCache;
});