define("ember-string-fns/helpers/string-repeat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringRepeat = stringRepeat;

  /**
   * Construct a single string by repeating the string a number of times.
   *
   * @public
   * @function stringRepeat
   * @param {string} value The starting string.
   * @param {number} repeat The total number of times to repeat.
   * @returns {string} The repeated string.
   */
  function stringRepeat(_ref) {
    let [value = '', repeat = 0] = _ref;
    let result = '';

    for (let i = 0; i < repeat; i++) {
      result += value;
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringRepeat);

  _exports.default = _default;
});