define("ember-composable-helpers/helpers/repeat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.repeat = repeat;

  function repeat(_ref) {
    let [length, value] = _ref;

    if (Ember.typeOf(length) !== 'number') {
      return [value];
    }

    return Array.apply(null, {
      length
    }).map(() => value); // eslint-disable-line
  }

  var _default = Ember.Helper.helper(repeat);

  _exports.default = _default;
});