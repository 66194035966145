define("ember-composable-helpers/helpers/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.map = map;

  function map(_ref) {
    let [callback, array] = _ref;

    if (Ember.isEmpty(callback)) {
      return [];
    }

    return array.map(callback);
  }

  var _default = Ember.Helper.helper(map);

  _exports.default = _default;
});