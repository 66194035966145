enifed('@ember/-internals/routing/lib/system/dsl', ['exports', 'ember-babel', '@ember/debug', '@ember/polyfills'], function (exports, _emberBabel, _debug, _polyfills) {
    'use strict';

    var uuid = 0;

    var DSL = function () {
        function DSL() {
            var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            var options = arguments[1];

            (0, _emberBabel.classCallCheck)(this, DSL);

            this.explicitIndex = false;
            this.parent = name;
            this.enableLoadingSubstates = !!(options && options.enableLoadingSubstates);
            this.matches = [];
            this.options = options;
        }

        DSL.prototype.route = function route(name) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var callback = arguments[2];

            var dummyErrorRoute = '/_unused_dummy_error_path_route_' + name + '/:error';
            if (arguments.length === 2 && typeof options === 'function') {
                callback = options;
                options = {};
            }
            true && !function () {
                if (options.overrideNameAssertion === true) {
                    return true;
                }
                return ['basic', 'application'].indexOf(name) === -1;
            }() && (0, _debug.assert)('\'' + name + '\' cannot be used as a route name.', function () {
                if (options.overrideNameAssertion === true) {
                    return true;
                }return ['basic', 'application'].indexOf(name) === -1;
            }());
            true && !(name.indexOf(':') === -1) && (0, _debug.assert)('\'' + name + '\' is not a valid route name. It cannot contain a \':\'. You may want to use the \'path\' option instead.', name.indexOf(':') === -1);

            if (this.enableLoadingSubstates) {
                createRoute(this, name + '_loading', {
                    resetNamespace: options.resetNamespace
                });
                createRoute(this, name + '_error', {
                    resetNamespace: options.resetNamespace,
                    path: dummyErrorRoute
                });
            }
            if (callback) {
                var fullName = getFullName(this, name, options.resetNamespace);
                var dsl = new DSL(fullName, this.options);
                createRoute(dsl, 'loading');
                createRoute(dsl, 'error', { path: dummyErrorRoute });
                callback.call(dsl);
                createRoute(this, name, options, dsl.generate());
            } else {
                createRoute(this, name, options);
            }
        };

        DSL.prototype.push = function push(url, name, callback, serialize) {
            var parts = name.split('.');
            if (this.options.engineInfo) {
                var localFullName = name.slice(this.options.engineInfo.fullName.length + 1);
                var routeInfo = (0, _polyfills.assign)({ localFullName: localFullName }, this.options.engineInfo);
                if (serialize) {
                    routeInfo.serializeMethod = serialize;
                }
                this.options.addRouteForEngine(name, routeInfo);
            } else if (serialize) {
                throw new Error('Defining a route serializer on route \'' + name + '\' outside an Engine is not allowed.');
            }
            if (url === '' || url === '/' || parts[parts.length - 1] === 'index') {
                this.explicitIndex = true;
            }
            this.matches.push(url, name, callback);
        };

        DSL.prototype.generate = function generate() {
            var dslMatches = this.matches;
            if (!this.explicitIndex) {
                this.route('index', { path: '/' });
            }
            return function (match) {
                for (var i = 0; i < dslMatches.length; i += 3) {
                    match(dslMatches[i]).to(dslMatches[i + 1], dslMatches[i + 2]);
                }
            };
        };

        DSL.prototype.mount = function mount(_name) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var engineRouteMap = this.options.resolveRouteMap(_name);
            var name = _name;
            if (options.as) {
                name = options.as;
            }
            var fullName = getFullName(this, name, options.resetNamespace);
            var engineInfo = {
                name: _name,
                instanceId: uuid++,
                mountPoint: fullName,
                fullName: fullName
            };
            var path = options.path;
            if (typeof path !== 'string') {
                path = '/' + name;
            }
            var callback = void 0;
            var dummyErrorRoute = '/_unused_dummy_error_path_route_' + name + '/:error';
            if (engineRouteMap) {
                var shouldResetEngineInfo = false;
                var oldEngineInfo = this.options.engineInfo;
                if (oldEngineInfo) {
                    shouldResetEngineInfo = true;
                    this.options.engineInfo = engineInfo;
                }
                var optionsForChild = (0, _polyfills.assign)({ engineInfo: engineInfo }, this.options);
                var childDSL = new DSL(fullName, optionsForChild);
                createRoute(childDSL, 'loading');
                createRoute(childDSL, 'error', { path: dummyErrorRoute });
                engineRouteMap.class.call(childDSL);
                callback = childDSL.generate();
                if (shouldResetEngineInfo) {
                    this.options.engineInfo = oldEngineInfo;
                }
            }
            var localFullName = 'application';
            var routeInfo = (0, _polyfills.assign)({ localFullName: localFullName }, engineInfo);
            if (this.enableLoadingSubstates) {
                // These values are important to register the loading routes under their
                // proper names for the Router and within the Engine's registry.
                var substateName = name + '_loading';
                var _localFullName = 'application_loading';
                var _routeInfo = (0, _polyfills.assign)({ localFullName: _localFullName }, engineInfo);
                createRoute(this, substateName, {
                    resetNamespace: options.resetNamespace
                });
                this.options.addRouteForEngine(substateName, _routeInfo);
                substateName = name + '_error';
                _localFullName = 'application_error';
                _routeInfo = (0, _polyfills.assign)({ localFullName: _localFullName }, engineInfo);
                createRoute(this, substateName, {
                    resetNamespace: options.resetNamespace,
                    path: dummyErrorRoute
                });
                this.options.addRouteForEngine(substateName, _routeInfo);
            }
            this.options.addRouteForEngine(fullName, routeInfo);
            this.push(path, fullName, callback);
        };

        return DSL;
    }();

    exports.default = DSL;

    function canNest(dsl) {
        return dsl.parent !== 'application';
    }
    function getFullName(dsl, name, resetNamespace) {
        if (canNest(dsl) && resetNamespace !== true) {
            return dsl.parent + '.' + name;
        } else {
            return name;
        }
    }
    function createRoute(dsl, name) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var callback = arguments[3];

        var fullName = getFullName(dsl, name, options.resetNamespace);
        if (typeof options.path !== 'string') {
            options.path = '/' + name;
        }
        dsl.push(options.path, fullName, callback, options.serialize);
    }
});