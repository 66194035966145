define("ember-string-fns/helpers/string-to-snake-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToSnakeCase = stringToSnakeCase;

  /**
   *  string_case a string.
   *
   * @public
   * @function stringToSnakeCase
   * @param {string} value The string to string_case.
   * @returns {string} The string_cased string.
   */
  function stringToSnakeCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().split(' ').join('_');
  }

  var _default = Ember.Helper.helper(stringToSnakeCase);

  _exports.default = _default;
});