define("ember-string-fns/helpers/string-to-camel-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToCamelCase = stringToCamelCase;

  /**
   * camelCase a string.
   *
   * @public
   * @function stringToCamelCase
   * @param {string} value The string to camelCase.
   * @returns {string} The camelCased string.
   */
  function stringToCamelCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().split(' ').map((word, index) => {
      return index === 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    }).join('');
  }

  var _default = Ember.Helper.helper(stringToCamelCase);

  _exports.default = _default;
});