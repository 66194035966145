enifed('@glimmer/util', ['exports', 'ember-babel'], function (exports, _emberBabel) {
    'use strict';

    exports.unreachable = exports.expect = exports.unwrap = exports.EMPTY_ARRAY = exports.ListSlice = exports.ListNode = exports.LinkedList = exports.EMPTY_SLICE = exports.dict = exports.DictSet = exports.Stack = exports.SERIALIZATION_FIRST_NODE_STRING = exports.isSerializationFirstNode = exports.initializeGuid = exports.ensureGuid = exports.fillNulls = exports.assign = exports.assert = undefined;

    function unwrap(val) {
        if (val === null || val === undefined) throw new Error('Expected value to be present');
        return val;
    }
    function expect(val, message) {
        if (val === null || val === undefined) throw new Error(message);
        return val;
    }
    function unreachable() {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'unreachable';

        return new Error(message);
    }

    // import Logger from './logger';
    // let alreadyWarned = false;
    function debugAssert(test, msg) {
        // if (!alreadyWarned) {
        //   alreadyWarned = true;
        //   Logger.warn("Don't leave debug assertions on in public builds");
        // }
        if (!test) {
            throw new Error(msg || 'assertion failure');
        }
    }

    var objKeys = Object.keys;

    function assign(obj) {
        for (var i = 1; i < arguments.length; i++) {
            var assignment = arguments[i];
            if (assignment === null || typeof assignment !== 'object') continue;
            var keys = objKeys(assignment);
            for (var j = 0; j < keys.length; j++) {
                var key = keys[j];
                obj[key] = assignment[key];
            }
        }
        return obj;
    }
    function fillNulls(count) {
        var arr = new Array(count);
        for (var i = 0; i < count; i++) {
            arr[i] = null;
        }
        return arr;
    }

    var GUID = 0;
    function initializeGuid(object) {
        return object._guid = ++GUID;
    }
    function ensureGuid(object) {
        return object._guid || initializeGuid(object);
    }

    var SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';
    function isSerializationFirstNode(node) {
        return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
    }

    function dict() {
        return Object.create(null);
    }

    var DictSet = function () {
        function DictSet() {
            (0, _emberBabel.classCallCheck)(this, DictSet);

            this.dict = dict();
        }

        DictSet.prototype.add = function add(obj) {
            if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
            return this;
        };

        DictSet.prototype.delete = function _delete(obj) {
            if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
        };

        return DictSet;
    }();

    var Stack = function () {
        function Stack() {
            (0, _emberBabel.classCallCheck)(this, Stack);

            this.stack = [];
            this.current = null;
        }

        Stack.prototype.push = function push(item) {
            this.current = item;
            this.stack.push(item);
        };

        Stack.prototype.pop = function pop() {
            var item = this.stack.pop();
            var len = this.stack.length;
            this.current = len === 0 ? null : this.stack[len - 1];
            return item === undefined ? null : item;
        };

        Stack.prototype.isEmpty = function isEmpty() {
            return this.stack.length === 0;
        };

        (0, _emberBabel.createClass)(Stack, [{
            key: 'size',
            get: function () {
                return this.stack.length;
            }
        }]);

        return Stack;
    }();

    var ListNode = function ListNode(value) {
        (0, _emberBabel.classCallCheck)(this, ListNode);

        this.next = null;
        this.prev = null;
        this.value = value;
    };

    var LinkedList = function () {
        function LinkedList() {
            (0, _emberBabel.classCallCheck)(this, LinkedList);

            this.clear();
        }

        LinkedList.prototype.head = function head() {
            return this._head;
        };

        LinkedList.prototype.tail = function tail() {
            return this._tail;
        };

        LinkedList.prototype.clear = function clear() {
            this._head = this._tail = null;
        };

        LinkedList.prototype.toArray = function toArray() {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        LinkedList.prototype.nextNode = function nextNode(node) {
            return node.next;
        };

        LinkedList.prototype.forEachNode = function forEachNode(callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = node.next;
            }
        };

        LinkedList.prototype.insertBefore = function insertBefore(node) {
            var reference = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            if (reference === null) return this.append(node);
            if (reference.prev) reference.prev.next = node;else this._head = node;
            node.prev = reference.prev;
            node.next = reference;
            reference.prev = node;
            return node;
        };

        LinkedList.prototype.append = function append(node) {
            var tail = this._tail;
            if (tail) {
                tail.next = node;
                node.prev = tail;
                node.next = null;
            } else {
                this._head = node;
            }
            return this._tail = node;
        };

        LinkedList.prototype.remove = function remove(node) {
            if (node.prev) node.prev.next = node.next;else this._head = node.next;
            if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
            return node;
        };

        return LinkedList;
    }();

    var ListSlice = function () {
        function ListSlice(head, tail) {
            (0, _emberBabel.classCallCheck)(this, ListSlice);

            this._head = head;
            this._tail = tail;
        }

        ListSlice.prototype.forEachNode = function forEachNode(callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = this.nextNode(node);
            }
        };

        ListSlice.prototype.head = function head() {
            return this._head;
        };

        ListSlice.prototype.tail = function tail() {
            return this._tail;
        };

        ListSlice.prototype.toArray = function toArray() {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        ListSlice.prototype.nextNode = function nextNode(node) {
            if (node === this._tail) return null;
            return node.next;
        };

        return ListSlice;
    }();

    var EMPTY_SLICE = new ListSlice(null, null);

    var EMPTY_ARRAY = Object.freeze([]);

    exports.assert = debugAssert;
    exports.assign = assign;
    exports.fillNulls = fillNulls;
    exports.ensureGuid = ensureGuid;
    exports.initializeGuid = initializeGuid;
    exports.isSerializationFirstNode = isSerializationFirstNode;
    exports.SERIALIZATION_FIRST_NODE_STRING = SERIALIZATION_FIRST_NODE_STRING;
    exports.Stack = Stack;
    exports.DictSet = DictSet;
    exports.dict = dict;
    exports.EMPTY_SLICE = EMPTY_SLICE;
    exports.LinkedList = LinkedList;
    exports.ListNode = ListNode;
    exports.ListSlice = ListSlice;
    exports.EMPTY_ARRAY = EMPTY_ARRAY;
    exports.unwrap = unwrap;
    exports.expect = expect;
    exports.unreachable = unreachable;
});