define("ember-string-fns/helpers/string-not-equals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringNotEquals = stringNotEquals;

  /**
   * Determine if two or more are not equal.
   *
   * @public
   * @function stringNotEquals
   * @param {...string} values The strings to compare.
   * @return {boolean} True if all values are equal.
   */
  function stringNotEquals(_ref) {
    let [...values] = _ref;

    // range check at least 2 operands
    if (values.length < 2) {
      return false;
    } // get first value


    const first = values.shift(); // compare first with each subsequent value for a non match

    return values.some(value => value !== first);
  }

  var _default = Ember.Helper.helper(stringNotEquals);

  _exports.default = _default;
});