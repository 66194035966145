define("ember-string-fns/helpers/string-code-point-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringCodePointAt = stringCodePointAt;

  /**
   * Get a code by code point.
   *
   * @public
   * @function stringCodePointAt
   * @param {string} value The string value.
   * @param {number} index The index to get the code at.
   * @returns {string} The string character.
   */
  function stringCodePointAt(_ref) {
    let [value = '', index] = _ref;
    return value.codePointAt(index);
  }

  var _default = Ember.Helper.helper(stringCodePointAt);

  _exports.default = _default;
});