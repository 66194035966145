define("ember-cli-string-helpers/helpers/trim", ["exports", "ember-cli-string-helpers/utils/trim", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _trim, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trim = _exports.default = void 0;
  const trim = (0, _createStringHelper.default)(_trim.default);
  _exports.trim = trim;

  var _default = Ember.Helper.helper(trim);

  _exports.default = _default;
});