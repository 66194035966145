define("ember-string-fns/helpers/string-pad-start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringPadStart = stringPadStart;

  /**
   * Pad the left portion of the string with another string up to a total string length.
   *
   * @public
   * @function stringPadStart
   * @param {string} value The starting string.
   * @param {string} padding The string to pad with.
   * @param {number} length The total number of characters before the function exits.
   * @returns {string} The padded string.
   */
  function stringPadStart(_ref) {
    let [value = '', padding = ' ', length = 0] = _ref;
    // ensure string values
    let paddedValue = value + '';
    const paddingValue = padding + ''; // padding value must be provided else infinite loop

    if (paddingValue.length > 0) {
      // do work
      while (paddedValue.length < length) {
        paddedValue = padding + paddedValue;
      } // overflow check


      if (paddedValue.length > length) {
        paddedValue = paddedValue.substring(paddedValue.length - length, paddedValue.length);
      }
    }

    return paddedValue;
  }

  var _default = Ember.Helper.helper(stringPadStart);

  _exports.default = _default;
});