define("ember-string-fns/helpers/string-replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringReplace = stringReplace;

  /**
   * Replace a string with matches found from another string.
   *
   * @public
   * @function stringReplace
   * @param {string} value The starting string.
   * @param {string} find The string to find.
   * @param {string} replace The string to replace with when found.
   * @param {boolean} ignoreCase Whether a case insensitive search should be performed.
   * @param {number} global Whether all matches should be replaced for just the first.
   * @returns {string} The replaced string.
   */
  function stringReplace(_ref) {
    let [value = '', find, replace = '', ignoreCase = false, global = false] = _ref;

    // ensure there is something to find
    if (!find) {
      return value;
    }

    let needle = find; // regex needed for case insentive or global replace

    if (ignoreCase || global) {
      // determine regex flags
      let flags = '';

      if (global) {
        flags += 'g';
      }

      if (ignoreCase) {
        flags += 'i';
      } // build regex


      needle = new RegExp(find, flags);
    } // return updated string


    return value.replace(needle, replace);
  }

  var _default = Ember.Helper.helper(stringReplace);

  _exports.default = _default;
});