define("ember-string-fns/helpers/string-concat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringConcat = stringConcat;

  /**
   * Concatenate a series of strings.
   *
   * @public
   * @function stringConcat
   * @param {...string} values The string values.
   * @returns {string} The concatenated string.
   */
  function stringConcat(_ref) {
    let [...values] = _ref;
    return values.reduce((accumulator, value) => {
      accumulator += value;
      return accumulator;
    }, '');
  }

  var _default = Ember.Helper.helper(stringConcat);

  _exports.default = _default;
});