define("ember-string-fns/helpers/string-substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringSubstring = stringSubstring;

  /**
   * Substring a string and return the new string.
   *
   * @public
   * @function stringSubstring
   * @param {string} value The string to substring.
   * @param {number} beginIndex The beginning index to substring.
   * @param {number} endIndex The optional end index to substring.
   * @returns {string} The substring string.
   */
  function stringSubstring(_ref) {
    let [value = '', beginIndex = 0, endIndex] = _ref;
    let result;

    if (endIndex === undefined) {
      result = value.substring(beginIndex);
    } else {
      result = value.substring(beginIndex, endIndex);
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringSubstring);

  _exports.default = _default;
});