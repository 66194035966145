define("ember-string-fns/helpers/string-to-lower-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToLowerCase = stringToLowerCase;

  /**
   * Lowercase a string.
   *
   * @public
   * @function stringToLowerCase
   * @param {string} value The string to lowercase.
   * @returns {string} The lowercased string.
   */
  function stringToLowerCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase();
  }

  var _default = Ember.Helper.helper(stringToLowerCase);

  _exports.default = _default;
});