define("ember-composable-helpers/helpers/reject-by", ["exports", "ember-composable-helpers/utils/is-equal"], function (_exports, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.rejectBy = rejectBy;

  function rejectBy(_ref) {
    let [byPath, value, array] = _ref;

    if (!Ember.isArray(array) && Ember.isArray(value)) {
      array = value;
      value = undefined;
    }

    if (!array) {
      array = [];
    }

    let filterFn;

    if (Ember.isPresent(value)) {
      if (typeof value === 'function') {
        filterFn = item => !value(Ember.get(item, byPath));
      } else {
        filterFn = item => !(0, _isEqual.default)(Ember.get(item, byPath), value);
      }
    } else {
      filterFn = item => !Ember.get(item, byPath);
    }

    return array.filter(filterFn);
  }

  var _default = Ember.Helper.helper(rejectBy);

  _exports.default = _default;
});