enifed('ember-babel', ['exports'], function (exports) {
  'use strict';

  exports.classCallCheck = classCallCheck;
  exports.inherits = inherits;
  exports.taggedTemplateLiteralLoose = taggedTemplateLiteralLoose;
  exports.createClass = createClass;


  var create = Object.create;
  var setPrototypeOf = Object.setPrototypeOf;
  var defineProperty = Object.defineProperty;

  function classCallCheck(instance, Constructor) {
    if (true /* DEBUG */) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function');
        }
      }
  }

  function inherits(subClass, superClass) {
    if (true /* DEBUG */) {
        if (typeof superClass !== 'function' && superClass !== null) {
          throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
        }
      }
    subClass.prototype = create(superClass === null ? null : superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass !== null) setPrototypeOf(subClass, superClass);
  }

  function taggedTemplateLiteralLoose(strings, raw) {
    strings.raw = raw;
    return strings;
  }

  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      defineProperty(target, descriptor.key, descriptor);
    }
  }

  function createClass(Constructor, protoProps, staticProps) {
    if (protoProps !== undefined) defineProperties(Constructor.prototype, protoProps);
    if (staticProps !== undefined) defineProperties(Constructor, staticProps);
    return Constructor;
  }

  var possibleConstructorReturn = exports.possibleConstructorReturn = function (self, call) {
    if (true /* DEBUG */) {
        if (!self) {
          throw new ReferenceError('this hasn\'t been initialized - super() hasn\'t been called');
        }
      }
    return call !== null && typeof call === 'object' || typeof call === 'function' ? call : self;
  };
});