enifed('@ember/-internals/routing/lib/system/router_state', ['exports', 'ember-babel', '@ember/polyfills', '@ember/-internals/routing/lib/utils'], function (exports, _emberBabel, _polyfills, _utils) {
    'use strict';

    var RouterState = function () {
        function RouterState(emberRouter, router, routerJsState) {
            (0, _emberBabel.classCallCheck)(this, RouterState);

            this.emberRouter = emberRouter;
            this.router = router;
            this.routerJsState = routerJsState;
        }

        RouterState.prototype.isActiveIntent = function isActiveIntent(routeName, models, queryParams, queryParamsMustMatch) {
            var state = this.routerJsState;
            if (!this.router.isActiveIntent(routeName, models, undefined, state)) {
                return false;
            }
            if (queryParamsMustMatch && Object.keys(queryParams).length > 0) {
                var visibleQueryParams = (0, _polyfills.assign)({}, queryParams);
                this.emberRouter._prepareQueryParams(routeName, models, visibleQueryParams);
                return (0, _utils.shallowEqual)(visibleQueryParams, state.queryParams);
            }
            return true;
        };

        return RouterState;
    }();

    exports.default = RouterState;
});