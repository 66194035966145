define("ember-string-fns/helpers/string-from-code-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringFromCodePoint = stringFromCodePoint;

  /**
   * Get a character from code points.
   *
   * @public
   * @function stringFromCodePoint
   * @param {...number} points The array of points.
   * @returns {string} The string sequence.
   */
  function stringFromCodePoint(_ref) {
    let [...points] = _ref;
    return String.fromCodePoint(...points);
  }

  var _default = Ember.Helper.helper(stringFromCodePoint);

  _exports.default = _default;
});