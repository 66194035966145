define("ember-string-fns/helpers/string-to-title-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToTitleCase = stringToTitleCase;

  /**
   *  Title Case a string.
   *
   * @public
   * @function stringToTitleCase
   * @param {string} value The string to Title Case.
   * @returns {string} The Title Cased string.
   */
  function stringToTitleCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().split(' ').map(word => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    }).join(' ');
  }

  var _default = Ember.Helper.helper(stringToTitleCase);

  _exports.default = _default;
});