define("ember-composable-helpers/helpers/next", ["exports", "ember-composable-helpers/utils/get-index", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _getIndex, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.next = next;

  function next(currentValue, array) {
    let useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (!array) {
      array = [];
    }

    let currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);
    let lastIndex = array.length - 1;

    if (Ember.isEmpty(currentIndex)) {
      return;
    }

    return currentIndex === lastIndex ? currentValue : Ember.A(array).objectAt(currentIndex + 1);
  }

  var _default = Ember.Helper.helper(function (params) {
    let {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return next(currentValue, array, useDeepEqual);
  });

  _exports.default = _default;
});