enifed("@ember/debug/lib/handlers", ["exports"], function (exports) {
    "use strict";

    var HANDLERS = exports.HANDLERS = {};
    var registerHandler = function () {};
    var invoke = function () {};
    if (true /* DEBUG */) {
            exports.registerHandler = registerHandler = function registerHandler(type, callback) {
                var nextHandler = HANDLERS[type] || function () {};
                HANDLERS[type] = function (message, options) {
                    callback(message, options, nextHandler);
                };
            };
            exports.invoke = invoke = function invoke(type, message, test, options) {
                if (test) {
                    return;
                }
                var handlerForType = HANDLERS[type];
                if (handlerForType) {
                    handlerForType(message, options);
                }
            };
        }
    exports.registerHandler = registerHandler;
    exports.invoke = invoke;
});