define("ember-string-fns/helpers/string-to-pascal-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringToPascalCase = stringToPascalCase;

  /**
   * PascalCase a string.
   *
   * @public
   * @function stringToPascalCase
   * @param {string} value The string to PascalCase.
   * @returns {string} The PascalCased string.
   */
  function stringToPascalCase(_ref) {
    let [value = ''] = _ref;
    return value.toLowerCase().split(' ').map(word => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    }).join('');
  }

  var _default = Ember.Helper.helper(stringToPascalCase);

  _exports.default = _default;
});