define("ember-string-fns/helpers/string-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringIncludes = stringIncludes;

  /**
   * Determine if string includes another string.
   *
   * @public
   * @function stringIncludes
   * @param {string} haystack The string to search.
   * @param {string} needle The string to search for.
   * @param {boolean} ignoreCase Whether case insenstive search should be performed.
   * @returns {boolean} True if match is found.
   */
  function stringIncludes(_ref) {
    let [haystack, needle, ignoreCase = false] = _ref;
    let result = false; // haystack and needle are required

    if (haystack && needle) {
      if (ignoreCase) {
        result = haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      } else {
        result = haystack.indexOf(needle) !== -1;
      }
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringIncludes);

  _exports.default = _default;
});