enifed("@ember/-internals/routing/lib/system/query_params", ["exports", "ember-babel"], function (exports, _emberBabel) {
    "use strict";

    var QueryParams = function QueryParams() {
        var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        (0, _emberBabel.classCallCheck)(this, QueryParams);

        this.isQueryParams = true;
        this.values = values;
    };

    exports.default = QueryParams;
});