enifed('@ember/map/with-default', ['exports', 'ember-babel', '@ember/debug', '@ember/map/index', '@ember/map/lib/utils', '@ember/deprecated-features'], function (exports, _emberBabel, _debug, _index, _utils, _deprecatedFeatures) {
  'use strict';

  var MapWithDefault = void 0;

  if (_deprecatedFeatures.MAP) {
    /**
    @class MapWithDefault
    @extends Map
    @private
    @constructor
    @param [options]
      @param {*} [options.defaultValue]
    */
    MapWithDefault = function (_Map) {
      (0, _emberBabel.inherits)(MapWithDefault, _Map);

      function MapWithDefault(options) {
        (0, _emberBabel.classCallCheck)(this, MapWithDefault);

        true && !false && (0, _debug.deprecate)('Use of @ember/MapWithDefault is deprecated. Please use native `Map` instead', false, {
          id: 'ember-map-deprecation',
          until: '3.5.0'
        });

        var _this = (0, _emberBabel.possibleConstructorReturn)(this, _Map.call(this));

        _this.defaultValue = options.defaultValue;
        return _this;
      }

      /**
      @method create
      @static
      @param [options]
        @param {*} [options.defaultValue]
      @return {MapWithDefault|Map} If options are passed, returns
        `MapWithDefault` otherwise returns `EmberMap`
      @private
      @deprecated use native `Map` instead
      */

      MapWithDefault.create = function create(options) {
        if (options) {
          return new MapWithDefault(options);
        } else {
          return new _index.default();
        }
      };

      /**
      Retrieve the value associated with a given key.
       @method get
      @param {*} key
      @return {*} the value associated with the key, or the default value
      @private
      */

      MapWithDefault.prototype.get = function get(key) {
        var hasValue = this.has(key);

        if (hasValue) {
          return _Map.prototype.get.call(this, key);
        } else {
          var defaultValue = this.defaultValue(key);
          this.set(key, defaultValue);
          return defaultValue;
        }
      };

      /**
      @method copy
      @return {MapWithDefault}
      @private
      */

      MapWithDefault.prototype.copy = function copy() {
        var Constructor = this.constructor;
        return (0, _utils.copyMap)(this, new Constructor({
          defaultValue: this.defaultValue
        }));
      };

      return MapWithDefault;
    }(_index.default);
  }

  exports.default = MapWithDefault;
});