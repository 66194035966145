define("ember-cli-string-helpers/helpers/w", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.w = w;

  function w(_ref) {
    let [...wordStrings] = _ref;
    return wordStrings.map(Ember.String.w).reduce((words, moreWords) => {
      return words.concat(moreWords);
    }, []);
  }

  var _default = Ember.Helper.helper(w);

  _exports.default = _default;
});