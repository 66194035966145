define("ember-string-fns/helpers/string-equals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringEquals = stringEquals;

  /**
   * Determine if two or more are exact equals.
   *
   * @public
   * @function stringEquals
   * @param {...string} values The strings to compare.
   * @return {boolean} True if all values are equal.
   */
  function stringEquals(_ref) {
    let [...values] = _ref;

    // range check at least 2 values
    if (values.length < 2) {
      return false;
    } // get first value


    const first = values.shift(); // compare first with each subsequent value for a match

    return values.every(value => value === first);
  }

  var _default = Ember.Helper.helper(stringEquals);

  _exports.default = _default;
});