define("ember-string-fns/helpers/string-char-code-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringCharCodeAt = stringCharCodeAt;

  /**
   * Get a character by utf-16 code.
   *
   * @public
   * @function stringCharCodeAt
   * @param {string} value The string value.
   * @param {number} index The index to get the character at.
   * @returns {string} The string character.
   */
  function stringCharCodeAt(_ref) {
    let [value = '', index] = _ref;
    return value.charCodeAt(index);
  }

  var _default = Ember.Helper.helper(stringCharCodeAt);

  _exports.default = _default;
});