define("ember-string-fns/helpers/string-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringSplit = stringSplit;

  /**
   * Split a string into a string[] by a delimiter.
   *
   * @public
   * @function stringSplit
   * @param {string} value The string split.
   * @param {string} delimiter The delimiter to split on.
   * @returns {string[]} The split string.
   */
  function stringSplit(_ref) {
    let [value = '', delimiter = ''] = _ref;
    return value.split(delimiter);
  }

  var _default = Ember.Helper.helper(stringSplit);

  _exports.default = _default;
});