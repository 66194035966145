define("ember-string-fns/helpers/string-trim-end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringTrimEnd = stringTrimEnd;

  /**
   * Trim the end of a string.
   *
   * @public
   * @function stringTrimEnd
   * @param {string} value The string to trim.
   * @returns {string} The trimmed string.
   */
  function stringTrimEnd(_ref) {
    let [value = ''] = _ref;
    return value.replace(/\+|[\s\uFEFF\xA0]+$/g, '');
  }

  var _default = Ember.Helper.helper(stringTrimEnd);

  _exports.default = _default;
});