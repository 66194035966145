define("ember-string-fns/helpers/string-last-index-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringLastIndexOf = stringLastIndexOf;

  /**
   * Determine the last index of a string within a string.
   *
   * @public
   * @function stringLastIndexOf
   * @param {string} haystack The string to search.
   * @param {string} needle The string to search for.
   * @param {boolean} ignoreCase Whether a case sensitive search should be performed.
   * @returns {number} Zero based index of first character in match from the end of the string else -1.
   */
  function stringLastIndexOf(_ref) {
    let [haystack, needle, ignoreCase = false] = _ref;
    let result = -1; // haystack and needle are required

    if (haystack && needle) {
      if (ignoreCase) {
        result = haystack.toLowerCase().lastIndexOf(needle.toLowerCase());
      } else {
        result = haystack.lastIndexOf(needle);
      }
    }

    return result;
  }

  var _default = Ember.Helper.helper(stringLastIndexOf);

  _exports.default = _default;
});